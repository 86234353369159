import Mock from "../mock";

const database = {
  information: {
    name: "Chris Holliday",
    aboutContent: "Full Stack Software Engineer",
    age: "",
    phone: "706-207-8543",
    nationality: "",
    language: "HTML, CSS, JS, React, Node",
    email: "chris@weboratory.dev",
    address: "",
    freelanceStatus: "Request for Availability",
    socialLinks: {
      weboratory: "https://www.weboratory.dev ",
      twitter: "https://twitter.com/developercsh",
      pinterest: "",
      behance: "",
      linkedin: "https://www.linkedin.com/in/chollid/",
      dribbble: "",
      github: "https://github.com/chollid",
    },
    brandImage: "/images/Adobe_CH_logo3.png",
    aboutImage: "/images/SiteImageChrisSmallCropped.jpg",
    aboutImageLg: "/images/SiteImageChrisSmall.jpg",
    cvfile: "/files/Chris_Holliday-Resume.pdf",
  },
  services: [
    {
      title: "Web Development",
      icon: "code",
      details:
        "From Static websites to fully functioning Web applications, frontend and backend I can carry development for your project.",
    },
    {
      title: "Web Design",
      icon: "color-pallet",
      details:
        "Website designs that stand out. My design focus is understanding then building a client's vision, while perfecting it's function.",
    },
    {
      title: "Mobile Development",
      icon: "mobile",
      details:
        "Let your users find you on both iOS and Android. Native Development allows us to create beautiful views for both mobile OS's.",
    },
  ],
  // reviews: [
  //   {
  //     id: 1,
  //     content:
  //       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
  //     author: {
  //       name: "Burdette Turner",
  //       designation: "Web Developer, Abc Company",
  //     },
  //   },
  //   {
  //     id: 2,
  //     content:
  //       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
  //     author: {
  //       name: "Susan Yost",
  //       designation: "Client",
  //     },
  //   },
  //   {
  //     id: 3,
  //     content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
  //     author: {
  //       name: "Irving Feeney",
  //       designation: "Fiverr Client",
  //     },
  //   },
  // ],
  skills: [
    {
      title: "HTML/CSS3",
      value: 95,
    },
    {
      title: "JavaScript",
      value: 75,
    },
    {
      title: "NodeJS",
      value: 70,
    },
    {
      title: "ReactJS",
      value: 80,
    },
    {
      title: "NoSQL",
      value: 75,
    },
    {
      title: "SQL",
      value: 70,
    },
  ],
  portfolios: [
    {
      id: 1,
      title: "Weboratory Website",
      subtitle: "HTML/CSS/JavaScript",
      imageUrl: "/images/3DMockupWeb.jpg",
      largeImageUrl: [
        "/images/weboratorySplash.jpg",
        "/images/weboratoryWorks.jpg",
      ],
      url: "https://www.weboratory.dev/works-page.html",
    },
    {
      id: 2,
      title: "CAPS Website",
      subtitle: "WordPress, PHP, MySQL",
      imageUrl: "/images/HELPlogo.jpg",
      largeImageUrl: ["/images/HELPlogo.jpg"],
      url: "https://www.capscanhelp.com",
    },
    {
      id: 3,
      title: "Amend iD (Mobile App)",
      subtitle: "React-Native, Redux, PostgreSQL, AWS EC2, Redis.",
      imageUrl: "/images/AmendIconsmall.jpg",
      largeImageUrl: [
        "/images/AmendPic3.jpg",
        "images/AmendPic2.jpg",
        "images/AmendPic1.jpg",
      ],
      url: "https://apps.apple.com/us/app/amend-id/id1579443807",
    },
    {
      id: 4,
      title: "COVID Resource Center (Web App)",
      subtitle: "React-Chart.js, Jest, React.js, Firebase",
      imageUrl: "/images/covidpage1.jpg",
      largeImageUrl: [
        "/images/covidSplash.jpg",
        "/images/covidpage22.jpg",
        "images/covidChatPage.jpg",
      ],
      url: "https://github.com/chollid/final-proj",
    },
    {
      id: 5,
      title: "Netflix (Web App)",
      subtitle: "Stripe, Firebase, React, Redux, Axios",
      imageUrl: "/images/netflixMain.jpg",
      largeImageUrl: ["/images/netflixOrder.jpg"],
      url: "https://github.com/chollid/netflix-clone1",
    },
    {
      id: 6,
      title: "Miro Splash Page",
      subtitle: "HTML & CSS.",
      imageUrl: "/images/miro.jpg",
      // largeImageUrl: ["/images/portfolio-image-6-lg.jpg"],
      url: "https://landing-page-weboratory-blob.netlify.app/",
    },
    {
      id: 7,
      title: "Droplit",
      subtitle: "HTML/CSS & JavaScript",
      imageUrl: "/images/droplit1.jpg",
      largeImageUrl: ["/images/droplit2.jpg"],
      url: "https://droplit.netlify.app/",
    },
    {
      id: 8,
      title: "Glassmorphism Credit Card",
      subtitle: "HTML & CSS.",
      imageUrl: "/images/glassCreditCard.jpg",
      // largeImageUrl: ["/images/portfolio-image-8-lg.jpg"],
      url: "https://glass-credit-card.netlify.app/",
    },
    {
      id: 9,
      title: "The Rosa",
      subtitle: "HTML/CSS & JavaScript",
      imageUrl: "/images/theRosaSplash.jpg",
      // largeImageUrl: ["/images/portfolio-image-9-lg.jpg"],
      url: "https://weboratory-csh-rosa-restaurant.netlify.app/",
    },
    // {
    //   id: 10,
    //   title: "Pen Holder",
    //   subtitle: "A pen holder with beautiful design.",
    //   imageUrl: "/images/portfolio-image-3.jpg",
    //   largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
    //   url: "#",
    // },
    // {
    //   id: 11,
    //   title: "Coffee Mug",
    //   subtitle: "Awesome coffee mug design.",
    //   imageUrl: "/images/portfolio-image-4.jpg",
    //   largeImageUrl: [
    //     "/images/portfolio-image-4-lg.jpg",
    //     "/images/portfolio-image-4-lg2.jpg",
    //   ],
    //   url: "https://facebook.com",
    // },
    // {
    //   id: 12,
    //   title: "Tea & Coffee Mug",
    //   subtitle: "Beautiful mug with logo.",
    //   imageUrl: "/images/portfolio-image-2.jpg",
    //   url: "https://pinterest.com",
    // },
    // {
    //   id: 13,
    //   title: "T-shirt Mockup",
    //   subtitle: "A beautiful t-shirt mockup.",
    //   imageUrl: "/images/portfolio-image-1.jpg",
    //   largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
    //   url: "https://dribbble.com",
    // },
    // {
    //   id: 14,
    //   title: "Mug",
    //   subtitle: "Mug with awesome style",
    //   imageUrl: "/images/portfolio-image-5.jpg",
    //   largeImageUrl: ["/images/portfolio-image-5-lg.jpg"],
    // },
    // {
    //   id: 15,
    //   title: "Pendrive",
    //   subtitle: "Free pendrive mockup design.",
    //   imageUrl: "/images/portfolio-image-6.jpg",
    //   largeImageUrl: ["/images/portfolio-image-6-lg.jpg"],
    // },
    // {
    //   id: 16,
    //   title: "Beautiful Pendrive",
    //   subtitle: "Pendrive with great design & flexible.",
    //   imageUrl: "/images/portfolio-image-7.jpg",
    //   largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
    //   url: "https://twitter.com",
    // },
    // {
    //   id: 17,
    //   title: "Sticker",
    //   subtitle: "Clip sticker mockup design.",
    //   imageUrl: "/images/portfolio-image-8.jpg",
    //   largeImageUrl: ["/images/portfolio-image-8-lg.jpg"],
    // },
    // {
    //   id: 18,
    //   title: "Packet",
    //   subtitle: "Beautiful packet & product design.",
    //   imageUrl: "/images/portfolio-image-9.jpg",
    //   largeImageUrl: ["/images/portfolio-image-9-lg.jpg"],
    // },
    // {
    //   id: 19,
    //   title: "T-shirt Mockup",
    //   subtitle: "A beautiful t-shirt mockup.",
    //   imageUrl: "/images/portfolio-image-1.jpg",
    //   largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
    //   url: "https://dribbble.com",
    // },
    // {
    //   id: 20,
    //   title: "Coffee Mug",
    //   subtitle: "Awesome coffee mug design.",
    //   imageUrl: "/images/portfolio-image-4.jpg",
    //   largeImageUrl: [
    //     "/images/portfolio-image-4-lg.jpg",
    //     "/images/portfolio-image-4-lg2.jpg",
    //   ],
    //   url: "https://facebook.com",
    // },
    // {
    //   id: 21,
    //   title: "Tea & Coffee Mug",
    //   subtitle: "Beautiful mug with logo.",
    //   imageUrl: "/images/portfolio-image-2.jpg",
    //   url: "https://pinterest.com",
    // },
    // {
    //   id: 22,
    //   title: "Pen Holder",
    //   subtitle: "A pen holder with beautiful design.",
    //   imageUrl: "/images/portfolio-image-3.jpg",
    //   largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
    //   url: "#",
    // },
    // {
    //   id: 23,
    //   title: "Mug",
    //   subtitle: "Mug with awesome style",
    //   imageUrl: "/images/portfolio-image-5.jpg",
    //   largeImageUrl: ["/images/portfolio-image-5-lg.jpg"],
    // },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2020-Present",
        position: "Lead Technical Consultant",
        company: "Crisis Assessment & Prevention Systems",
        details:
          "Responsible for expanding the company's technical capabilities in order to grow client base and revenue opportunities. Solely created and maintain the business' 26 page website. Built an online education module that provides clients easy access to our product - which is also new source of revenue. Made an online platform for company-staff (licensed agents) to communicate with current clients, expediting our business processes and creating more time for new clients. Within the website we integrated payments (Stripe & PayPal), Certificate Generator written in PHP, as well as webhooks with forms and Google Sheets for automatic record keeping and mail-merging.",
      },
      {
        id: 2,
        year: "2020 - June 2021",
        position: "Frontend Engineer",
        company: "Mporium",
        details:
          "Worked on an Agile Development Team designing, developing, and testing a mobile-first web application as well as an iOS mobile app. Successfully created and continuously improved a MVP for our company using FIGMA, SASS, React.JS, React-Native, Redux (w/Thunk), Jest, AWS ecosystem (DynamoDB, Amplify, Cognito), GraphQL, and Github.",
      },
      {
        id: 3,
        year: "May 2021 - July 2021",
        position: "Lead Frontend Developer",
        company: "Amend iD Mobile Application",
        details:
          "Lead team to create an iOS and Android mobile Contacts Application utilizing React-Native, Redux, AWS EC2, PostgreSQL, and GraphQL. Led all project management and was first point of contact with Design Team in order to ensure development timelines. Met and exceeded expectations on a tight deadline, completing the build and getting the application passed to both mobile stores in 3 months.",
      },
      {
        id: 4,
        year: "2020 - Present",
        position: "Owner - Engineer",
        company: "Weboratory Development",
        details:
          "Established a web development and design company focused on ushering small businesses into a post-COVID online world. Through the website I created, and marketing outreach I performed, my business has been able to help companies obtain thriving web presences and find new revenue opportunities online. From Business Strategy, Brand Identity, and Social Media Integration to Web and Mobile App Development, I'm lucky enough to be of service at every stage.",
      },
    ],
    educationExperience: [
      {
        id: 1,
        year: "2020",
        graduation: "Software Engineering Immersive",
        university: "General Assembly",
        // details:
        //   "6-Month intensive Full-Stack Engineer school. Beginning with basics of computer science to HTML/CSS/Javascript, through React.JS, Node.JS, MongoDB, Express, commencing with a 80+ hour final project. (COVID-DataCenter on works page)",
      },
      {
        id: 2,
        year: "2006-2011",
        graduation: "Bachelor of Science - Economics",
        university: "University of Georgia",
        details: "",
      },
      // {
      //   id: 3,
      //   year: "2015 - 2016",
      //   graduation: "Higher Schoold Graduation",
      //   university: "Abc College",
      //   details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      // }
    ],
  },
  blogs: [
    {
      id: 1,
      title: "Coming Soon: Quirks of React-Native.",
      featuredImage: "/images/rnimage.jpg",
      filesource: "../../blog/markdown-html-supported-blog.md",
      createDay: "21",
      createMonth: "July",
      createYear: "2021",
    },
    // {
    //   id: 2,
    //   title: "Installing NodeJS on your device.",
    //   featuredImage: "/images/blog-image-2.jpg",
    //   filesource: "../../blog/installing-nodejs-on-your-device.md",
    //   createDay: "20",
    //   createMonth: "February",
    //   createYear: "2020",
    // },
    // {
    //   id: 3,
    //   title: "UI/UX design starter with Adobe XD.",
    //   featuredImage: "/images/blog-image-3.jpg",
    //   filesource: "../../blog/uiux-design-starter-with-adobe-xd.md",
    //   createDay: "20",
    //   createMonth: "February",
    //   createYear: "2020",
    // },
    // {
    //   id: 4,
    //   title: "Boost your post for increasing sales.",
    //   featuredImage: "/images/blog-image-4.jpg",
    //   filesource: "../../blog/boost-your-post-for-increasing-sales.md",
    //   createDay: "20",
    //   createMonth: "February",
    //   createYear: "2020",
    // },
    // {
    //   id: 5,
    //   title: "Difference between GatsbyJS & NextJS.",
    //   featuredImage: "/images/blog-image-5.jpg",
    //   filesource: "../../blog/difference-between-gatsbyjs-and-nextjs.md",
    //   createDay: "20",
    //   createMonth: "February",
    //   createYear: "2020",
    // },
    // {
    //   id: 6,
    //   title: "How to choose javascript framework for project.",
    //   featuredImage: "/images/blog-image-6.jpg",
    //   filesource:
    //     "../../blog/how-to-choose-javascript-framework-for-project.md",
    //   createDay: "20",
    //   createMonth: "February",
    //   createYear: "2020",
    // },
    // {
    //   id: 7,
    //   title: "Web automation with python language.",
    //   featuredImage: "/images/blog-image-7.jpg",
    //   filesource: "../../blog/web-automation-with-python-language.md",
    //   createDay: "20",
    //   createMonth: "February",
    //   createYear: "2020",
    // },
    // {
    //   id: 8,
    //   title: "Time to use latest technology for creating a website.",
    //   featuredImage: "/images/blog-image-8.jpg",
    //   filesource:
    //     "../../blog/time-to-use-latest-technology-for-creating-a-website.md",
    //   createDay: "20",
    //   createMonth: "February",
    //   createYear: "2020",
    // },
    // {
    //   id: 9,
    //   title: "Think out of the box.",
    //   featuredImage: "/images/blog-image-9.jpg",
    //   filesource: "../../blog/think-out-of-the-box.md",
    //   createDay: "20",
    //   createMonth: "February",
    //   createYear: "2020",
    // },
    // {
    //   id: 10,
    //   title: "Trending designs in 2020.",
    //   featuredImage: "/images/blog-image-1.jpg",
    //   filesource: "../../blog/trending-designs-in-2020.md",
    //   createDay: "20",
    //   createMonth: "February",
    //   createYear: "2020",
    // },
    // {
    //   id: 11,
    //   title: "How to get 10k instagram followers?",
    //   featuredImage: "/images/blog-image-2.jpg",
    //   filesource: "../../blog/how-to-get-10k-instagram-followers.md",
    //   createDay: "20",
    //   createMonth: "February",
    //   createYear: "2020",
    // },
    // {
    //   id: 12,
    //   title: "What NodeJS can do?",
    //   featuredImage: "/images/blog-image-3.jpg",
    //   filesource: "../../blog/what-nodejs-can-do.md",
    //   createDay: "20",
    //   createMonth: "February",
    //   createYear: "2020",
    // },
    // {
    //   id: 13,
    //   title: "Futures of javascript.",
    //   featuredImage: "/images/blog-image-4.jpg",
    //   filesource: "../../blog/future-of-javascript.md",
    //   createDay: "20",
    //   createMonth: "February",
    //   createYear: "2020",
    // },
    // {
    //   id: 14,
    //   title: "Popular javascript library in 2020.",
    //   featuredImage: "/images/blog-image-5.jpg",
    //   filesource: "../../blog/popular-javascript-library-in-2020.md",
    //   createDay: "20",
    //   createMonth: "February",
    //   createYear: "2020",
    // },
    // {
    //   id: 15,
    //   title: "Promrammers must read books.",
    //   featuredImage: "/images/blog-image-6.jpg",
    //   filesource: "../../blog/programmers-must-read-books.md",
    //   createDay: "20",
    //   createMonth: "February",
    //   createYear: "2020",
    // },
  ],
  contactInfo: {
    phoneNumbers: ["706-207-8543"],
    emailAddress: [
      "Business: chris@weboratory.dev",
      "Personal: cshdevelopment1@gmail.com",
    ],
    address: "Greenville, SC",
  },
};

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply((config) => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply((config) => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});
